import { colors } from '../../../../theme';
import { size } from 'polished';
import styled from 'styled-components';

const SocialLinkWrapper = styled.div`
  display: inline-flex;

  button {
    outline: 0;

    & > svg {
      ${size('1.5rem')};

      color: ${colors.n08};
    }

    &:hover {
      & > svg {
        color: ${colors.n06};
      }
    }
  }

  & + & {
    margin-left: 1.25rem;
  }
`;

export { SocialLinkWrapper };
