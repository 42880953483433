import { DocumentStyle } from '../../../../theme/components';
import { RichText } from 'prismic-react-tools';
import PropTypes from 'prop-types';
import React from 'react';

export const Body = props => {
  const { content } = props;

  return (
    <DocumentStyle>
      <RichText content={content} />
    </DocumentStyle>
  );
};

Body.propTypes = {
  content: PropTypes.any
};
