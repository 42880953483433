import { Div, Icon } from '../../../../theme/components';
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from 'react-share';
import { PageContextConsumer } from '../../../../components';
import { SocialLinkWrapper } from './ShareWidget.style';
import PropTypes from 'prop-types';
import React from 'react';

export const ShareWidget = props => {
  const { description, title } = props;

  const social = {
    facebook: {
      Component: FacebookShareButton,
      quote: description
    },
    twitter: {
      Component: TwitterShareButton,
      title
    },
    // eslint-disable-next-line sort-keys
    linkedin: {
      Component: LinkedinShareButton,
      summary: description,
      title
    }
  };

  return (
    <PageContextConsumer>
      {({ location: { href: url } }) => (
        <Div>
          {Object.keys(social).map((network, index) => {
            const { Component, ...socialProps } = social[network];

            return (
              <SocialLinkWrapper key={index}>
                <Component {...socialProps} url={url}>
                  <Icon icon={network} />
                </Component>
              </SocialLinkWrapper>
            );
          })}
        </Div>
      )}
    </PageContextConsumer>
  );
};

ShareWidget.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string
};
