import { FileItem, FileLink, FileLinkIcon, FilesWrapper } from './Files.style';
import PropTypes from 'prop-types';
import React from 'react';

export const Files = props => {
  const { files, isMobile } = props;

  return (
    <FilesWrapper isMobile={isMobile}>
      {files.map(({ label, file: { url } }, index) => (
        <FileItem key={index}>
          <FileLink href={url}>
            <FileLinkIcon />
            {label}
          </FileLink>
        </FileItem>
      ))}
    </FilesWrapper>
  );
};

Files.propTypes = {
  files: PropTypes.arrayOf(
    PropTypes.shape({
      file: PropTypes.shape({
        url: PropTypes.string
      }),
      label: PropTypes.string
    })
  ),
  isMobile: PropTypes.bool
};
