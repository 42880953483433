import { Body, Files, ShareWidget } from './components';
import { Col, Div, DocumentStyle, Grid, Img, Row, Section } from '../../theme/components';
import { LocalLink } from '../../components';
import { RichText, Slices, extractFromCamelCase } from 'prismic-react-tools';
import { Text, Heading as Title } from '../../theme/components/Typography';
import { get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import withPrismicPage from '../../HOC/withPrismicPage';

const Article = props => {
  const {
    pageContext: { document, common }
  } = props;

  const { backToNews: backToNewsLabel } = extractFromCamelCase(common, 'strings');
  const { body, date, description, files, image, slices, title } = document;
  const shareWidget = { description, title };

  const documentDate = new Date(date);

  return (
    <>
      {/* eslint-disable-next-line no-extra-boolean-cast */}
      {!!slices?.length ? (
        <>
          <Section pt={{ sm: 8, xs: 6 }}>
            <Grid>
              <Row>
                <Col left xs={6}>
                  <LocalLink href="/news">
                    <Text underline XSmall>
                      {backToNewsLabel}
                    </Text>
                  </LocalLink>
                </Col>
                <Col right xs={6}>
                  <ShareWidget {...shareWidget} />
                </Col>
              </Row>
              <Row mt={{ sm: 5, xs: 2.5 }}>
                <Col sm={4} xs={12}>
                  <Img src={image?.url} />
                </Col>
                <Col mt={{ sm: 0, xs: 3 }} sm={7} xs={12}>
                  <Text isLabel n04 XSmall>
                    {documentDate.toDateString()}
                  </Text>
                  <Title h2 left mt={0.5}>
                    {title}
                  </Title>
                  <Div mt={{ sm: 3.75, xs: 2 }}>
                    <DocumentStyle>
                      <RichText content={body} />
                    </DocumentStyle>
                  </Div>
                </Col>
              </Row>
            </Grid>
          </Section>
          <Slices slices={slices} />
          {!isEmpty(files) && (
            <Section pb={{ md: 5, xs: 2.5 }}>
              <Grid>
                <Row>
                  <Col xs={12}>
                    <Files files={files} />
                  </Col>
                </Row>
              </Grid>
            </Section>
          )}
        </>
      ) : (
        <Section pb={{ sm: 10, xs: 5 }} pt={{ sm: 8, xs: 6 }}>
          <Grid>
            <Row>
              <Col left md={10} mdOffset={1} xs={12}>
                <LocalLink href="/news">
                  <Text underline XSmall>
                    {backToNewsLabel}
                  </Text>
                </LocalLink>
              </Col>
            </Row>
            <Row mt={2}>
              <Col md={3} mdOffset={1} sm={4} xs={12}>
                {!isEmpty(image) && <Img mb={{ sm: 2, xs: 1 }} src={get(image, 'url')} />}
                <ShareWidget {...shareWidget} />
                {!isEmpty(files) && <Files files={files} />}
              </Col>
              <Col md={7} mt={{ sm: 0, xs: 2 }} sm={8} xs={12}>
                <Text isLabel n04 XSmall>
                  {documentDate.toDateString()}
                </Text>
                <Title h2 left mb={{ md: 3.75, xs: 2 }} mt={0.5}>
                  {title}
                </Title>
                <Body content={body} />
                {!isEmpty(files) && <Files files={files} isMobile />}
              </Col>
            </Row>
          </Grid>
        </Section>
      )}
    </>
  );
};

Article.propTypes = {
  pageContext: PropTypes.object
};

export default withPrismicPage(Article);
