import { Icon } from '../../../../theme/components';
import { colors } from '../../../../theme';
import { mq } from 'styled-gen';
import { size } from 'polished';
import styled, { css } from 'styled-components';

const FileItem = styled.div`
  & + & {
    margin-top: 1rem;
  }
`;

const FileLink = styled.a.attrs({
  rel: 'noopener noreferrer',
  target: '_blank'
})`
  align-items: center;
  color: ${colors.n06};
  display: inline-flex;

  ${mq.tabletLandscape(css`
    &:hover {
      color: ${colors.n07};
    }
  `)}
`;

const FileLinkIcon = styled(Icon).attrs({
  icon: 'fileBlankO'
})`
  ${size('1.25rem')};

  margin-right: 0.25rem;
`;

const FilesWrapper = styled.div`
  border-top: 1px solid ${colors.n05};
  padding-top: 2rem;
  margin-top: 2rem;

  ${({ isMobile }) => css`
    display: ${isMobile ? 'block' : 'none'};

    ${mq.tablet(css`
      display: ${isMobile ? 'none' : 'block'};
    `)}
  `}
`;

export { FileItem, FileLink, FileLinkIcon, FilesWrapper };
